<template>
  <section role="region" class="our-channels-section" :class="isPartner ? 'partner-page' : ''" id="our-channels"
    ref="ourChannelsSection">
    <div class="content-image">
      <ThePicture :mobilePath="mobilePath" :desktopPath="desktopPath" mobileQuerie="833px" desktopQuerie="834px"
        altText="Pessoa com celular na mão sorrindo" />
    </div>
    <div class="content-text">
      <TheSecondHead class="header" chipText="Onde estamos"
        :titleText="isPartner ? 'Entramos em contato com você por meio de:' : 'O MeLembre está onde você precisa, quando você precisa'" />
      <div class="channels">
        <div class="channel" :class="makeFade ? 'show' : ''" v-for="channel in channels" :key="channel.id"
          v-show="showTexts" :style="`transition-duration: ${(channel.id + 1) * 0.4}s !important`">
          <img :src="`/images/${iconsPath}${channel.mediaSrc}`" :alt="`Ícone de ${channel.title}`"
            loading="lazy" />
          <h3>{{ channel.title }}</h3>
          <p>{{ channel.text }} </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useChannelsStore } from '@/stores/home/channels'
const store = useChannelsStore();

const channels = store.getOurChannels;

defineProps<{
  iconsPath: string,
  mobilePath: string,
  desktopPath: string,
  isPartner?: boolean,
}>()

const showTexts = ref<Boolean>(false);
const makeFade = ref<Boolean>(false);
const ourChannelsSection = ref<any>(null);

const ourChannelsWatcher = () => {
  var section = ourChannelsSection.value;

  if ((window.scrollY + section.offsetHeight) >= section.offsetTop) {
    setTimeout(() => {
      makeFade.value = true;
    }, 100);
    window.removeEventListener('scroll', ourChannelsWatcher);
  }
}

onMounted(() => {
  showTexts.value = true;
  window.addEventListener('scroll', ourChannelsWatcher, {passive: true});
  ourChannelsWatcher();
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', ourChannelsWatcher);
})
</script>

<style lang="scss">
.our-channels-section {
  padding: 40px 15px 15px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .content-image {
    margin-bottom: 40px;
  }

  .content-text {
    .header {
      text-align: center;

      .text-chip {
        margin-bottom: 16px;
      }

      .title {
        font-size: 36px;
        line-height: 110%;
        margin-bottom: 40px;
      }
    }

    .channels {
      .channel {
        text-align: center;
        margin-bottom: 24px;

        h3 {
          margin: 12px 0;
          line-height: 110%;
        }

        p {
          width: 210px;
          margin: 0 auto;
        }

        &:nth-child(1) p,
        &:nth-child(3) p {
          width: 199px;
        }

        &:nth-child(2) p {
          width: 240px;
        }


        &:nth-child(4) p {
          width: 204px;
        }
      }
    }
  }

  @media screen and (min-width: 834px) and (max-width: 959px) {
    .content-text {
      max-width: 570px;

      .header .title {
        margin-bottom: 26px;
      }

      .channels {
        justify-content: space-around;

        .channel {
          margin-bottom: 34px;
        }
      }
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1199px) {
    max-width: 1200px;
    padding: 68px 0px;

    .content-image img {
      max-width: 100%;
    }

    .content-text {
      max-width: 551px;
    }
  }

  @media screen and (min-width: 600px) {
    .content-text .channels {
      display: grid;
      grid-template-columns: auto auto;
    }

  }

  @media screen and (min-width: 834px) {
    .content-text .header .title {
      font-size: 40px;
    }
  }

  @media screen and (min-width: 960px) {
    flex-direction: row;

    .content-image {
      margin-bottom: 0px;
    }

    .content-text {

      .header,
      .channels .channel {
        text-align: start;
        margin-bottom: 0px;
      }

      .header .title {
        margin-bottom: 34px;
      }

      .channels .channel {

        &:nth-child(1) p,
        &:nth-child(3) p {
          margin: 0;
        }

        &:nth-child(2) p {
          margin: 0px 0px 34px;
        }


        &:nth-child(4) p {
          margin: 0;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    max-width: 1146px;
    padding: 68px 0px;

    .content-image img {
      width: 522px;
      height: 593px;
      margin-right: 63px;
    }

    .content-text {
      padding-right: 38px;
    }

  }

  // Animação de aparecer
  .channel {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  .show {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }

  // Fim animação de aparecer
}
</style>